<template>
  <div>
    <component
      :is="info.component"
      :param="popupParam"
    />
  </div>
</template>
<script>
export default {
  name: 'wasteMasterManage',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          title: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      info: { 
        name: 'tab1', component: () => import(`${'./wasteMaster01.vue'}`) 
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
    },
  }
};
</script>